<template>
  <header>
    <div class="menu">
      <router-link to="/" @click="store.commit('setWindow', 'home')" v-show="store.state.window !== 'home'">
        <img src="@/assets/home2.svg" alt="home">
      </router-link>
      <h1>DeepTalk</h1>
      <router-link to="/about" @click="store.commit('setWindow', 'about')">
        <img src="@/assets/info3.svg" alt="about" v-show="store.state.window !== 'about'">
      </router-link>
    </div>
  </header>
</template>

<script setup>
import {useStore} from "vuex";

const store = useStore();
</script>

<style scoped>
header {
  width: 100%;
  min-height: var(--header-height);
  position: fixed;
  top: 0;
  background-color: var(--color-accent);
  border-bottom: var(--border);

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.menu{
  width: 90%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  margin: 0;
}

a {
  width: 15%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
}

a:hover {
  background-color: var(--color-selected);
}

a img{
  height: 80%;
  width: auto;
}


</style>

import {createApp} from 'vue/dist/vue.esm-bundler';
import {createStore} from 'vuex'
import {createRouter, createWebHistory} from "vue-router/dist/vue-router";

import App from './App.vue'
import AppInfo from './components/AppInfo'
import DeckMenu from './components/DeckMenu'

// Create a new store instance.
const store = createStore({
    state() {
        return {
            window: "home"
        }
    },
    mutations: {
        setWindow(state, newWindow) {
            state.window = newWindow
            console.log(newWindow)
        }
    }
})

// Create Router
const routes = [
    {path: '/', component: DeckMenu},
    {
        path: '/about',
        component: AppInfo,
        beforeEnter: () => {
            store.commit('setWindow', 'about')
        }},
]

// Capacitor ads
import {Capacitor} from "@capacitor/core";

if (Capacitor.isNativePlatform() === false) {
    let script = document.createElement('script');
    script.async = true;
    script.crossOrigin = "anonymous";
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5602777568715946";
    document.head.appendChild(script);
}

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')



<template>
  <div class="QuestionCard">
    <div class="inside">
      <h2 class="questionText">{{ text }}</h2>
    </div>
  </div>
</template>

<script setup>
import {defineProps, toRefs} from "vue";

const props = defineProps({
  text: String,
});

const {text} = toRefs(props);

</script>

<style scoped>
.QuestionCard {
  height: 65vh;
  width: 100%;

  margin: 7vh 0 25vh 0;
  border-radius: 2.5vw;
  background-color: var(--color-accent);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  justify-content: center;
  align-items: center;

  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.QuestionCard .inside {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionText {
  text-align: center;
}

.QuestionCard h2 {
  padding: 5%;
}
</style>

<template>
    <div class="DeckMenu">
      <div class="buttonColumn" v-show="store.state.window === 'home'">
        <p id="Desc">
          DeepTalk is a free conversation starter. Gather some friends, pick a category, answer abstract questions and have fun :)
        </p>
        <h1>Categories:</h1>
        <DeckButton class="DeckButton" v-for="(pack, index) in deckPack" :key="index" :deckName="pack.name" :questions="pack.questions"/>
      </div>
      <TheDeck class="TheDeck" v-for="(pack, index) in deckPack" :key="index" :deckName="pack.name" :questions="pack.questions"/>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useStore} from "vuex";
import DeckButton from "@/components/DeckButton";
import TheDeck from "@/components/TheDeck";
import qJson from '@/assets/productionQuestions.json';

const store = useStore();
let deckPack = ref([]);

function loadDeckPack(deckArray) {
  for (let deck of deckArray) {
    deckPack.value.push({
      name: deck.name,
      questions: deck.questions
    });
  }
}

onMounted(() => {
  loadDeckPack(qJson)
  // fetchJson.get("https://rejnek.cz/deeptalkshared/a2e1a039092300b8c1e67b329bd2f7d3/productionQuestions.json").then((packs) => loadDeckPack(packs));
})

</script>

<style scoped>
.DeckMenu {
  width: 90%;
  margin: var(--header-height) auto 0 auto;
}

#Desc {
  text-align: center;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
}

.buttonColumn{
  min-height: 100%;
}

.buttonColumn p {
  margin: 0;
}
</style>

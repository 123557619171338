<template>
  <div class="container">
    <div class="AppInfo">
      <h1>
        About:
      </h1>
      <p>
        DeepTalk is a free conversation starter. It might help you if you are bored, unable to come with fresh ideas or
        if you want to explore yourself and connect with your friends.
      </p>
      <p>
        There are no rules so you can skip some questions that you don't like. The categories may help with that,
        though.
      </p>
      <h2>
        Questions made by
      </h2>
      <h2>
        JS, KK, KR, KR and SQ
      </h2>
      <h2>
        with 💛
      </h2>
      <div v-show="Capacitor.isNativePlatform() === false">
        <h1>
          Privacy policy:
        </h1>
        <p>
          As you can see, there is Google Ad service enabled. Google and some other third party vendors use cookies for
          user identification and serving targeted ads. You can opt out of this service by visiting <a
            href="https://www.google.com/settings/ads">Ads Settings</a>. Alternatively, you can opt out of a third-party
          vendor's use of cookies by visiting <a href="https://www.aboutads.info">aboutads.info</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Capacitor} from "@capacitor/core";
</script>

<style scoped>
.container{
  width: 100%;
  margin-top: calc(var(--header-height) + 1rem);

  display: flex;
  align-items: center;
  justify-content: center;
}

.AppInfo {
  width: 90%;
  max-height: 100%;
}

h1 {
  margin-bottom: 0.5rem;
}

h2 {
  margin: 0.5rem;
}
</style>
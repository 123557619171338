<template>
  <div class="App">
    <MyHeader />
    <router-view></router-view>
  </div>
</template>

<script setup>
import MyHeader from "@/components/MyHeader";
import {Capacitor} from "@capacitor/core";

if(Capacitor.isNativePlatform()){
  document.documentElement.style.setProperty('--vh', `100vh`);
}
else{
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
  window.onresize = () => {document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);}
}
</script>

<style>

html, body{
  /* --color-accent: #3cbddc; */
  --color-accent: #ffbe00;
  --color-selected: #dddddd;
  --color-bg: whitesmoke;
  --border: none;
  --header-height: 3.3rem;

  width: 100vw;
  height: 100vh;

  background: var(--color-bg);

  margin: 0 auto;
  padding: 0;

  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
  color: black;

  user-select: none;

  scroll-snap-type: y mandatory;
}

h1{
  font-weight: normal;
  margin: 0;
}

h2{
  font-weight: normal;
}

p{
  margin-top: 0.3rem;
  font-size: 1.1rem;
  text-align: justify;
}

a:focus, a:active{
  -webkit-tap-highlight-color: transparent;
}

.App{
  width: 100%;
  height: 100vh;

  display: flex;
  flex-flow: column;
}

button{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: 0.5s;
  -webkit-tap-highlight-color: transparent;
}


</style>

<template>
  <div class="NavigationVertical">
    <button @click="scrollTo('up')">
      <img src="@/assets/up.svg" alt="info">
    </button>
    <button @click="scrollTo('down')">
      <img src="@/assets/down.svg" alt="info">
    </button>
  </div>
</template>

<script setup>
function scrollTo(direction) {
  console.log(window.innerHeight)

  let sign = 1;
  if(direction === 'up') sign = -1;
  window.scrollBy({
    top: sign * window.innerHeight,
    left: 0,

  });

}
</script>

<style scoped>
.NavigationVertical{
  display: flex;
  justify-content: center;

}

.NavigationVertical button{
  width: 20%;
  height: fit-content;
  background-color: var(--color-accent);

  margin: 2%;

  border: var(--border);
  border-radius: 10px;

  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.NavigationVertical button:hover{
  background-color: var(--color-selected);
}

.NavigationVertical button img{
  width: auto;
  height: 30px;
  padding: 10%;
}
</style>

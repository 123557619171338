<template>
    <button @click="store.commit('setWindow', 'question' + deckName); vibrate();">
        <h2>
          {{ deckName }}
        </h2>
    </button>
</template>

<script setup>
import {defineProps, toRefs} from "vue";
import {useStore} from "vuex";

const store = useStore();

// load properties
const props = defineProps({
  deckName: String,
  questions: Array
});

const {deckName} = toRefs(props);

function vibrate(){
  navigator.vibrate(100);
}

</script>

<style scoped>

button:hover
{
  background-color: var(--color-selected);
}

button {
  width: 100%;
  max-width: 70rem;
  margin: 1vh 0 1vh 0;

  background-color: var(--color-accent);
  border: var(--border);
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

button h2 {
  margin: 4%;
}
</style>

<template>
  <div class="TheDeck" v-show="store.state.window === 'question' + deckName">
    <div class="Questions" :id="'question' + deckName">
      <QuestionCard v-for="(text, index) in questions" :key="index" :text="text"/>
    </div>
    <NavigationVertical class="NavigationVertical" :scroll-object-id="'question' + deckName" />
  </div>

</template>

<script setup>
import QuestionCard from "@/components/QuestionCard";
import {defineProps, toRefs} from "vue";
import {useStore} from "vuex";
import NavigationVertical from "@/components/NavigationVertical";

const store = useStore()

// loading properties
const props = defineProps({
  deckName: String,
  questions: Array
});
const {questions} = toRefs(props);
</script>

<style scoped>
.TheDeck{
  width: 100%;
}

.Questions {
  padding: 1vh;
}

.NavigationVertical{
  position: fixed;
  bottom: 4vh;
  left: 0;
  width: inherit;
}

</style>
